import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import * as types from '@/vuex/types'

import api from '@/api'
import { apiUrls } from '@/api/urls'

// Vue.use(Vuex)

const state = {
  sm: 575,
  md: 769,
  lg: 991,
  user: '',
  account: {
    token: '',
    isSuperuser: '',
    isStaff: '',
    groups: '',
    userId: '',
    profile: ''
  },
}

const getters = {
  user: state => state.user,
  account: state => state.account,
  token: state => state.account.token,
  userId: state => state.account.userId,
  profileImage: state => {
    const profile = state.account.profile
    if (typeof profile === 'object') {
      const { avatar } = profile
      return avatar !== undefined ? avatar : false
    } else {
      return false
    }
  },
  isSuperuser: state => state.account.isSuperuser,
  isStaff: state => state.account.isStaff,
  groups: state => state.account.groups,
  profile: state => state.account.profile || null
}

const mutations = {
  [types.GET_ACCOUNT] (state, response) {
    state.account = response
  },
  [types.GET_PROFILE] (state, response) {
    state.account.profile = response
  },
  [types.REMOVE_ACCOUNT] (state) {
    const defaultState = {
      'token': '',
      'isSuperuser': '',
      'isStaff': '',
      'groups': '',
      'userId': '',
      'profile': ''
    }

    state.account = defaultState
  },
  [types.SET_TOKEN] (state, token) {
    state.account.token = token
  },
  [types.REMOVE_APOLLO_AUTH] (state) {
    localStorage.removeItem('apollo-token')
  },
  [types.ADD_APOLLO_AUTH](state) {
    localStorage.setItem('apollo-token', state.account.token);
  },
}

const actions = {
  async getAccount ({ commit }, payload) {
    commit(types.GET_ACCOUNT, payload)
  },
  async removeAccount ({ dispatch, commit }, payload) {
    commit(types.REMOVE_ACCOUNT)
    await dispatch(`removeApolloToken`)
    // await dispatch(`removeBusOrder`)
  },
  async checkToken ({ commit }) {
    const response = await api.get(apiUrls.authCheck, { needToken: true })
    if (response.status === 200) {
      commit(types.GET_ACCOUNT, response.data)
    }
  },
  async setProfile ({ dispatch, commit }) {
    const responseProfile = await api.get(apiUrls.profileMe, { needToken: true })
    if (responseProfile.status === 200) {
      await commit(types.GET_PROFILE, responseProfile.data.profile)
      // await dispatch(`setApolloToken`)
    }
  },
  async tokenFromActivation ({ commit }, payload) {
    commit(types.SET_TOKEN, payload.token)
  },
  async removeApolloToken ({ commit }) {
    commit(types.REMOVE_APOLLO_AUTH)
  },
  async setApolloToken ({ commit }) {
    commit(types.ADD_APOLLO_AUTH)
  },
}

export default new createStore({
  state,
  getters,
  mutations,
  actions,
  plugins: [createPersistedState({
    reducer: state => ({
      account: state.account
    })
  })]
})
