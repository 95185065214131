// Page Website
const Home = () => import(/* webpackChunkName: "Home" */ '../views/Home')
const Contact = () => import(/* webpackChunkName: "Contact Us" */ '../views/Contact')
const Login = () => import(/* webpackChunkName: "Contact Us" */ '../views/Login')


const Init = [
    {
        path: '/',
        name: 'home',
        redirect: '/dashboard',
        component: Home
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
]

export default Init;
