import { createRouter, createWebHistory } from "vue-router"

import initial from '@/routerList/init.js'
import dashboard from '@/routerList/dashboard.js'
import billboardMap from '@/routerList/billboardMap.js'
import utilityRouter from '@/routerList/utility.js'

import store from '@/store'

const notFound = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

// Vue.use(Router)

const mainrouter = new createRouter({
  history: createWebHistory(),
  routes: [
    ...initial,
    ...dashboard,
    ...billboardMap,
    ...utilityRouter,
    ...notFound,
  ],
})

mainrouter.beforeEach((to, from, next) => {  
  const token = store.getters.token
  const superuser = store.getters.isSuperuser
  const staff = store.getters.isStaff
  const groups = store.getters.groups.split(',')

  if (to.matched.some(record => record.meta.isLogin)) {
    if (token) {
      next()
    } else {
      next({ name: 'login', query: { next: to.fullPath } })
    }
  }else if (to.matched.some(record => record.meta.isLinkAja)) {
      const hasLinkAjaPermisson = groups.includes('linkaja')
      if (token && (superuser || hasLinkAjaPermisson || staff)) {
        next()
      } else {
        next({name: 'login', query: { next: to.fullPath} })
      }
  }else if (to.matched.some(record => record.meta.adminDashboard)) {
    const hasAdminDashboardPermisson = groups.includes('adminDashboard')
    if (token && (superuser || hasAdminDashboardPermisson || staff)) {
      next()
    } else {
      next({name: 'login', query: { next: to.fullPath} })
    }
  } else{
    next()
  }
});

export default mainrouter;
