import axios from 'axios'
import { baseUri } from '@/lib/config'
import store from '@/store'


const config = {}

function setConfig(useRestEdge, tmpHeaders, tmpParams){
  config.params = tmpParams || undefined
  config.headers = tmpHeaders

  if(useRestEdge){
    config.baseURL = edgeUri
  } else {
    config.baseURL = baseUri
  }

}

function getHeader (addtionalHeaders = {}) {
  const tokenFromStore = store.getters.token
  return {
    Authorization: `Bearer ${tokenFromStore}`,
    ...addtionalHeaders
  }
}

export const get = async (url, options = {}) => {
  const { params, needToken, useRestEdge, headers, ...others } = options

  if (needToken) {
    setConfig(useRestEdge, getHeader(headers), params || undefined)
  } else {
    setConfig(useRestEdge, { ...headers || undefined}, params || undefined)
  }

  try {
    const response = await axios.get(url, { ...config, ...others })
    return response
  } catch (error) {
    return error.response
  }
}

export const post = async (url, body, options = {}) => {
  const { params, needToken, useRestEdge, headers, ...others } = options
  config.params = params || undefined

  if (needToken) {
    setConfig(useRestEdge, getHeader(headers), params || undefined)
  } else {
    setConfig(useRestEdge, { ...headers || undefined}, params || undefined)
  }

  try {
    const response = await axios.post(url, body, { ...config, ...others })
    return response
  } catch (error) {
    return error.response
  }
}

export const put = async (url, body = {}, options = {}) => {
  const { params, needToken, useRestEdge, headers, ...others } = options
  config.params = params || undefined

  if (needToken) {
    setConfig(useRestEdge, getHeader(headers), params || undefined)
  } else {
    setConfig(useRestEdge, { ...headers || undefined}, params || undefined)
  }

  try {
    const response = await axios.put(url, body, { ...config, ...others })
    return response
  } catch (error) {
    return error.response
  }
}

export const patch = async (url, body = {}, options = {}) => {
  const { params, needToken, useRestEdge, headers, ...others } = options
  config.params = params || undefined

  if (needToken) {
    setConfig(useRestEdge, getHeader(headers), params || undefined)
  } else {
    setConfig(useRestEdge, { ...headers || undefined}, params || undefined)
  }

  try {
    const response = await axios.patch(url, body, { ...config, ...others })
    return response
  } catch (error) {
    return error.response
  }
}

export const destroy = async (url, options = {}) => {
  const { params, needToken, useRestEdge, headers, ...others } = options
  config.params = params || undefined

  if (needToken) {
    setConfig(useRestEdge, getHeader(headers), params || undefined)
  } else {
    setConfig(useRestEdge, { ...headers || undefined}, params || undefined)
  }

  try {
    const response = await axios.delete(url, { ...config, ...others })
    return response
  } catch (error) {
    return error.response
  }
}

const api = {
  get,
  post,
  put,
  patch,
  destroy
}
export default api
