export const baseUri = process.env.VUE_APP_BASE_URI || `http://localhost:8010/api/`
export const backendUrl = process.env.VUE_APP_API_HOST
export const sshrDomain = process.env.VUE_APP_SSHR_DOMAIN
export const viewPrice = process.env.VUE_APP_SHOW_PRICE
export const googleMapKey = process.env.VUE_APP_GOOGLE_MAP_KEY
export const dashboardInsightLimitDays = process.env.VUE_APP_DASHBOARD_INSIGHT_LIMIT_DAYS

const config = {
  baseUri,
  backendUrl,
  sshrDomain,
  viewPrice,
  googleMapKey,
  dashboardInsightLimitDays
}

export default config
