<template>
  <notifications :group="group" :duration="duration" :position="position()" :max="3" :class="getClass()">
    <template #body="props">
      <div class="alert" :class="props.item.type" @click="remove">
        <i class="adx-ic_close" v-if="isClose"></i>
        <h5 class="title">{{props.item.title}}</h5>
        <p class="small-font content-alert">{{props.item.text}}</p>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'metrics-alert',
  props: {
    title: {
      required: false
    },
    content: {
      required: false
    },
    type: {
      required: false
    },
    group: {
      required: true
    },
    classes: {
      required: false
    },
    duration: {
      required: false
    },
    isClose: {
      required: false,
      default: false
    }
  },
  methods: {
    position() {
      if (window.innerWidth < 769) {
        return 'top center'
      }else {
        return 'top right'
      }
    },
    getClass() {
      if (window.innerWidth < 769) {
        return `margin_top_mobile ${this.classes}`
      }else {
        return `${this.classes}`
      }
    },
    remove() {
      this.$notify({
        group: this.group,
        clean: true
      })
    }
  }
}
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>