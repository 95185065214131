const SovMap = () => import(/* webpackChunkName: "SovMap" */ '../views/SovMap')
const EditPlacement = () => import(/* webpackChunkName: "SovMap" */ '../views/SovMap/EditPlacement')
const AddSov = () => import('../views/SovMap/AddSov')
const PlacementCreation = () => import('../views/SovMap/AddSov/PlacementCreation')
const SideCreation = () => import('../views/SovMap/AddSov/SideCreation')
const Content = () => import(/* webpackChunkName: "SovMap" */ '../views/SovMap/EditPlacement/Content')
const PlacementDetail = () => import(/* webpackChunkName: "SovMap" */ '../views/SovMap/EditPlacement/PlacementDetail')
const CaptureInsight = () => import(/* webpackChunkName: "CaptureInsight" */ '../views/Dashboard/Measurement/CaptureInsight')
// const MeasurementImpact = () => import(/* webpackChunkName: "MeasurementImpact" */ '../views/Dashboard/Measurement/MeasureImpact')
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard')
const DashboardOverview = () => import(/* webpackChunkName: "First" */ '../views/Dashboard/Overview')
const MonitoringReportList = () => import(/* webpackChunkName: "MonitoringPlacement" */ '../views/Dashboard/Monitoring/ReportList')
const MonitoringReport = () => import(/* webpackChunkName: "MonitoringReport" */ '../views/Dashboard/Monitoring/Report')
const MonitoringSummary = () => import(/* webpackChunkName: "MonitoringSummary" */ '../views/Dashboard/Monitoring/Summary')
const MonitoringPlacement = () => import(/* webpackChunkName: "MonitoringPlacement" */ '../views/Dashboard/Monitoring/Placement')
const MeasurementReport = () => import('../views/Dashboard/MeasurementReport');
const DownloadDailyReport = () => import('../views/DownloadReport');
const KmlConverter = () => import(/* webpackChunkName: "KmlConverter" */ '../views/Utility/KmlConverter')


const sovRouter = [
    {
      path: '/sov-map',
      name: 'sovmap',
      component: SovMap,
      meta: { isLinkAja: true }
    },
    {
      path: '/add-sov',
      name: 'addsov',
      component: AddSov,
      meta: { isLinkAja: true },
      children: [
        {
          path: '/add-placement',
          name: 'placement-creation',
          component: PlacementCreation
        },
        {
          path: '/add-side',
          name: 'side-creation',
          component: SideCreation
        }
      ]
    },
    {
      path: '/edit-placement',
      name: 'edit-placement',
      component: EditPlacement,
      redirect: '/edit-placement/content/:id',
      children: [
        {
          path: '/edit-placement/content/:id',
          name: 'content',
          component: Content,
        },
        {
          path: '/edit-placement/placement-detail/:id',
          name: 'placement-detail',
          component: PlacementDetail,
        },
      ]
    },
    {
    path: '/dashboard',
    name: 'dashboard',
    redirect: { name: 'measurement-report' },
    component: Dashboard,
    meta: { adminDashboard: true, isLogin: true },
    children: [
      {
        path: 'overview',
        name: 'dashboard-overview',
        component: DashboardOverview
      },
      {
        path: 'monitoring-report-list',
        name: 'monitoring-report-list',
        component: MonitoringReportList
      },
      {
        path: 'measurement-report',
        name: 'measurement-report',
        component: MeasurementReport
      },
      {
        path: 'download-daily-report',
        name: 'download-daily-report',
        component: DownloadDailyReport
      },
      {
          path: 'kml-converter',
          name: 'kmlconverterdas',
          component: KmlConverter,
          meta: { isLinkAja: true }
      }
    ]
    },
    {
      path: '/monitoring-report/:pk',
      name: 'monitoring-report',
      redirect: { name: 'monitoring-summary' },
      component: MonitoringReport,
      meta: { adminDashboard: true, isLogin: true },
      children: [
        {
          path: 'summary',
          name: 'monitoring-summary',
          component: MonitoringSummary,
        },
        {
          path: 'placement',
          name: 'monitoring-placement',
          component: MonitoringPlacement,
        },
      ]
    },
    {
      path: '/capture-insight/:pk',
      name: 'capture-insight',
      component: CaptureInsight,
      meta: { adminDashboard: true, isLogin: true }
    },
    // {
    //   path: '/measurement-impact',
    //   name: 'measurement-impact',
    //   component: MeasurementImpact,
    //   meta: { adminDashboard: true, isLinkAja: true }
    // },
]

export default sovRouter;
