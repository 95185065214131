<template>
  <div id="app" @click.stop="onClick">
    <metrics-alert :group="'notif'" :duration="3000" />
    <main class="container">
      <router-view />
    </main>
  </div>
</template>

<script>
import { createApp } from 'vue'
import Alert from "@/components/Alert"

const app = createApp({})
// Vue.$broker = new Vue();

export default {
  name: 'App',
  components: {
    'metrics-alert': Alert,
  },
  emits: [
    'app-click',
    'on-scroll'
  ],
  methods: {
    onClick(event) {
      this.$emit("app-click");
    },
    onScroll(event) {
      this.$emit('on-scroll')
    },
  },
}
</script>

<style lang="scss">
@import "~@/styles/global";
// @import "~@/styles/base";
@import "~@/styles/typography";
@import "~@/styles/mixins";
// @import "~@/styles/fonts";
@import "~@/styles/adx-font_icon";
@import "~@/styles/icons";
</style>
