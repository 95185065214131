import { createApp, configureCompat } from 'vue'
import App from './App.vue'
import router from './mainrouter'
import store from './store'
import Notifications from '@kyvg/vue3-notification'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Third Party JS
import  VueGoogleMaps from '@fawmi/vue-google-maps'
import VCalendar from 'v-calendar'
import 'v-calendar/style.css';
import VueApexCharts from "vue3-apexcharts";

// ADX Library
import { googleMapKey } from '@/lib/config'

configureCompat({ 
  RENDER_FUNCTION: false,
  WATCH_ARRAY: false,
  COMPONENT_V_MODEL: false,
  INSTANCE_ATTRS_CLASS_STYLE: false, 
  COMPONENT_ASYNC: false,
  ATTR_FALSE_VALUE: 'suppress-warning'
})

const app = createApp(App)

app.use(Notifications)

// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app')

app.use(router)
app.use(store)

app.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyBjO6FTUUvlrsAjd5tkjKxrM0Pvs9Gi_wo',
    key: googleMapKey,
    libraries: 'places',
    v: 'quarterly'
  },

})

app.use(VCalendar, {
  datePickerTintColor: '#e5e5ea',
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['DD MMM YYYY'],
    dayPopover: 'L',
    data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD']
  }
})

app.use(VueApexCharts)
app.mount('#app')
